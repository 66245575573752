import PropTypes from 'prop-types';
import ancient_scale from '../assets/treasures/ancient_scale.png';
import apple from '../assets/treasures/apple.png';
import astral_runespell from '../assets/treasures/astral_runespell.png';
import bacon from '../assets/treasures/bacon.png';
import battery from '../assets/treasures/battery.png';
import blind_missiles from '../assets/treasures/blind_missiles.png';
import boomerang from '../assets/treasures/boomerang.png';
import bread from '../assets/treasures/bread.png';
import brisingamen from '../assets/treasures/brisingamen.png';
import butcher_knife from '../assets/treasures/butcher_knife.png';
import carnivorous_seed from '../assets/treasures/carnivorous_seed.png';
import catapult from '../assets/treasures/catapult.png';
import cheese from '../assets/treasures/cheese.png';
import chronos_chain from '../assets/treasures/chronos_chain.png';
import colorful_brush from '../assets/treasures/colorful_brush.png';
import compass from '../assets/treasures/compass.png';
import counter_gauntlet from '../assets/treasures/counter_gauntlet.png';
import crossbow from '../assets/treasures/crossbow.png';
import cursed_collar from '../assets/treasures/cursed_collar.png';
import cursed_doll from '../assets/treasures/cursed_doll.png';
import cursed_totem from '../assets/treasures/cursed_totem.png';
import daggers from '../assets/treasures/daggers.png';
import dark_claw from '../assets/treasures/dark_claw.png';
import dark_runestone from '../assets/treasures/dark_runestone.png';
import destruction_switch from '../assets/treasures/destruction_switch.png';
import diamond_pickaxe from '../assets/treasures/diamond_pickaxe.png';
import dice from '../assets/treasures/dice.png';
import divine_anchor from '../assets/treasures/divine_anchor.png';
import divine_cross from '../assets/treasures/divine_cross.png';
import divinitys_stone from '../assets/treasures/divinitys_stone.png';
import dragonic_horn from '../assets/treasures/dragonic_horn.png';
import earth_runestone from '../assets/treasures/earth_runestone.png';
import elemental_runespell from '../assets/treasures/elemental_runespell.png';
import elixir from '../assets/treasures/elixir.png';
import elven_quiver from '../assets/treasures/elven_quiver.png';
import fire_runestone from '../assets/treasures/fire_runestone.png';
import frost_fang from '../assets/treasures/frost_fang.png';
import ghost_cloak from '../assets/treasures/ghost_cloak.png';
import gjallarhorn from '../assets/treasures/gjallarhorn.png';
import glacier_hearth from '../assets/treasures/glacier_hearth.png';
import gleipnir from '../assets/treasures/gleipnir.png';
import gram from '../assets/treasures/gram.png';
import grinder_of_eir from '../assets/treasures/grinder_of_eir.png';
import guardian_key from '../assets/treasures/guardian_key.png';
import gungnir from '../assets/treasures/gungnir.png';
import heaven_brand from '../assets/treasures/heaven_brand.png';
import heavy_shield from '../assets/treasures/heavy_shield.png';
import hell_brand from '../assets/treasures/hell_brand.png';
import heroic_runespell from '../assets/treasures/heroic_runespell.png';
import ice_runestone from '../assets/treasures/ice_runestone.png';
import javalin from '../assets/treasures/javalin.png';
import laevateinn from '../assets/treasures/laevateinn.png';
import lantern_grenade from '../assets/treasures/lantern_grenade.png';
import life_elixir from '../assets/treasures/life_elixir.png';
import light_runestone from '../assets/treasures/light_runestone.png';
import lightning_runestone from '../assets/treasures/lightning_runestone.png';
import magician_runespell from '../assets/treasures/magician_runespell.png';
import magma_core from '../assets/treasures/magma_core.png';
import mana_elixir from '../assets/treasures/mana_elixir.png';
import manis_horseshoe from '../assets/treasures/manis_horseshoe.png';
import megaphone from '../assets/treasures/megaphone.png';
import megingjord from '../assets/treasures/megingjord.png';
import mighty_horn from '../assets/treasures/mighty_horn.png';
import mightys_stone from '../assets/treasures/mightys_stone.png';
import mirage_shield from '../assets/treasures/mirage_shield.png';
import mjolnir from '../assets/treasures/mjolnir.png';
import mystical_fin from '../assets/treasures/mystical_fin.png';
import nectar from '../assets/treasures/nectar.png';
import night_wing from '../assets/treasures/night_wing.png';
import ocarina from '../assets/treasures/ocarina.png';
import philosophers_stone from '../assets/treasures/philosophers_stone.png';
import poison_runestone from '../assets/treasures/poison_runestone.png';
import potato from '../assets/treasures/potato.png';
import power_elixir from '../assets/treasures/power_elixir.png';
import purify_water from '../assets/treasures/purify_water.png';
import rainbow_feather from '../assets/treasures/rainbow_feather.png';
import ring_of_faith from '../assets/treasures/ring_of_faith.png';
import ring_of_power from '../assets/treasures/ring_of_power.png';
import ring_of_wisdom from '../assets/treasures/ring_of_wisdom.png';
import scale_of_hildr from '../assets/treasures/scale_of_hildr.png';
import seeker_runespell from '../assets/treasures/seeker_runespell.png';
import shadow_blade from '../assets/treasures/shadow_blade.png';
import shuriken from '../assets/treasures/shuriken.png';
import skolls_paw from '../assets/treasures/skolls_paw.png';
import sols_horseshoe from '../assets/treasures/sols_horseshoe.png';
import sparrow_feather from '../assets/treasures/sparrow_feather.png';
import spearhead_of_sigrun from '../assets/treasures/spearhead_of_sigrun.png';
import stellar_runestone from '../assets/treasures/stellar_runestone.png';
import stop_watch from '../assets/treasures/stop_watch.png';
import survival_book from '../assets/treasures/survival_book.png';
import tactical_book from '../assets/treasures/tactical_book.png';
import talisman from '../assets/treasures/talisman.png';
import tempest_bracelet from '../assets/treasures/tempest_bracelet.png';
import thief_boots from '../assets/treasures/thief_boots.png';
import thief_clothe from '../assets/treasures/thief_clothe.png';
import thief_gloves from '../assets/treasures/thief_gloves.png';
import valkyrie_belt from '../assets/treasures/valkyrie_belt.png';
import valkyrie_blessing from '../assets/treasures/valkyrie_blessing.png';
import valkyrie_gloves from '../assets/treasures/valkyrie_gloves.png';
import valkyrie_helmet from '../assets/treasures/valkyrie_helmet.png';
import venom_cell from '../assets/treasures/venom_cell.png';
import volund_hammer from '../assets/treasures/volund_hammer.png';
import warrior_runespell from '../assets/treasures/warrior_runespell.png';
import whirlwind_axe from '../assets/treasures/whirlwind_axe.png';
import wind_runestone from '../assets/treasures/wind_runestone.png';
import wine from '../assets/treasures/wine.png';

const IMAGE_MAP = {
  ancient_scale,
  apple,
  astral_runespell,
  bacon,
  battery,
  blind_missiles,
  boomerang,
  bread,
  brisingamen,
  butcher_knife,
  carnivorous_seed,
  catapult,
  cheese,
  chronos_chain,
  colorful_brush,
  compass,
  counter_gauntlet,
  crossbow,
  cursed_collar,
  cursed_doll,
  cursed_totem,
  daggers,
  dark_claw,
  dark_runestone,
  destruction_switch,
  diamond_pickaxe,
  dice,
  divine_anchor,
  divine_cross,
  divinitys_stone,
  dragonic_horn,
  earth_runestone,
  elemental_runespell,
  elixir,
  elven_quiver,
  fire_runestone,
  frost_fang,
  ghost_cloak,
  gjallarhorn,
  glacier_hearth,
  gleipnir,
  gram,
  grinder_of_eir,
  guardian_key,
  gungnir,
  heaven_brand,
  heavy_shield,
  hell_brand,
  heroic_runespell,
  ice_runestone,
  javalin,
  laevateinn,
  lantern_grenade,
  life_elixir,
  light_runestone,
  lightning_runestone,
  magician_runespell,
  magma_core,
  mana_elixir,
  manis_horseshoe,
  megaphone,
  megingjord,
  mighty_horn,
  mightys_stone,
  mirage_shield,
  mjolnir,
  mystical_fin,
  nectar,
  night_wing,
  ocarina,
  philosophers_stone,
  poison_runestone,
  potato,
  power_elixir,
  purify_water,
  rainbow_feather,
  ring_of_faith,
  ring_of_power,
  ring_of_wisdom,
  scale_of_hildr,
  seeker_runespell,
  shadow_blade,
  shuriken,
  skolls_paw,
  sols_horseshoe,
  sparrow_feather,
  spearhead_of_sigrun,
  stellar_runestone,
  stop_watch,
  survival_book,
  tactical_book,
  talisman,
  tempest_bracelet,
  thief_boots,
  thief_clothe,
  thief_gloves,
  valkyrie_belt,
  valkyrie_blessing,
  valkyrie_gloves,
  valkyrie_helmet,
  venom_cell,
  volund_hammer,
  warrior_runespell,
  whirlwind_axe,
  wind_runestone,
  wine,
};

export const TreasureImage = (props) => {
  const { treasureId, ...rest } = props ?? {};
  const source = IMAGE_MAP[treasureId];

  if (!source) {
    return null;
  }

  return <img src={source} {...rest} />;
};

TreasureImage.propTypes = {
  treasureId: PropTypes.string.isRequired,
};
