import PropTypes from 'prop-types';
import adventurer from '../assets/classes/adventurer.png';
import alchemist from '../assets/classes/alchemist.png';
import apprentice from '../assets/classes/apprentice.png';
import arcanist from '../assets/classes/arcanist.png';
import archer from '../assets/classes/archer.png';
import archmage from '../assets/classes/archmage.png';
import artist from '../assets/classes/artist.png';
import assassin from '../assets/classes/assassin.png';
import bard from '../assets/classes/bard.png';
import berserker from '../assets/classes/berserker.png';
import black_mage from '../assets/classes/black_mage.png';
import blue_mage from '../assets/classes/blue_mage.png';
import brawler from '../assets/classes/brawler.png';
import cait_sidhe from '../assets/classes/cait_sidhe.png';
import cat from '../assets/classes/cat.png';
import chieftain from '../assets/classes/chieftain.png';
import cleric from '../assets/classes/cleric.png';
import cryomancer from '../assets/classes/cryomancer.png';
import dragoon from '../assets/classes/dragoon.png';
import druid from '../assets/classes/druid.png';
import gamer from '../assets/classes/gamer.png';
import gunner from '../assets/classes/gunner.png';
import gunslinger from '../assets/classes/gunslinger.png';
import hermit from '../assets/classes/hermit.png';
import hunter from '../assets/classes/hunter.png';
import idol from '../assets/classes/idol.png';
import illustrator from '../assets/classes/illustrator.png';
import knight from '../assets/classes/knight.png';
import lancer from '../assets/classes/lancer.png';
import maid from '../assets/classes/maid.png';
import marionette from '../assets/classes/marionette.png';
import martial_artist from '../assets/classes/martial_artist.png';
import mecha from '../assets/classes/mecha.png';
import musician from '../assets/classes/musician.png';
import ninja from '../assets/classes/ninja.png';
import power_lifter from '../assets/classes/power_lifter.png';
import priest from '../assets/classes/priest.png';
import pyromancer from '../assets/classes/pyromancer.png';
import red_mage from '../assets/classes/red_mage.png';
import rogue from '../assets/classes/rogue.png';
import ronin from '../assets/classes/ronin.png';
import rune_knight from '../assets/classes/rune_knight.png';
import sage from '../assets/classes/sage.png';
import samurai from '../assets/classes/samurai.png';
import scout from '../assets/classes/scout.png';
import servant from '../assets/classes/servant.png';
import singer from '../assets/classes/singer.png';
import streamer from '../assets/classes/streamer.png';
import summoner from '../assets/classes/summoner.png';
import tamer from '../assets/classes/tamer.png';
import trainer from '../assets/classes/trainer.png';
import treasure_hunter from '../assets/classes/treasure_hunter.png';
import warlock from '../assets/classes/warlock.png';
import warlord from '../assets/classes/warlord.png';
import warrior from '../assets/classes/warrior.png';
import wizard from '../assets/classes/wizard.png';

const IMAGE_MAP = {
  adventurer,
  alchemist,
  apprentice,
  arcanist,
  archer,
  archmage,
  artist,
  assassin,
  bard,
  berserker,
  black_mage,
  blue_mage,
  brawler,
  cait_sidhe,
  cat,
  chieftain,
  cleric,
  cryomancer,
  dragoon,
  druid,
  gamer,
  gunner,
  gunslinger,
  hermit,
  hunter,
  idol,
  illustrator,
  knight,
  lancer,
  maid,
  marionette,
  martial_artist,
  mecha,
  musician,
  ninja,
  power_lifter,
  priest,
  pyromancer,
  red_mage,
  rogue,
  ronin,
  rune_knight,
  sage,
  samurai,
  scout,
  servant,
  singer,
  streamer,
  summoner,
  tamer,
  trainer,
  treasure_hunter,
  warlock,
  warlord,
  warrior,
  wizard,
};

export const ClassImage = (props) => {
  const { classId, ...rest } = props ?? {};
  const source = IMAGE_MAP[classId];

  if (!source) {
    return null;
  }

  return <img src={source} {...rest} />;
};

ClassImage.propTypes = {
  classId: PropTypes.string.isRequired,
};
